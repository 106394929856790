import { Nullable } from '@tgg/common-types';
import { EventKey, dispatchEvent } from '@tgg/services';
import { FlagsConstants } from '@tgg/util';

export const dispatchOptimizelyGaEvent = (event: {
    flagKey: string;
    ruleKey?: Nullable<string>;
    variationKey?: Nullable<string>;
    flagsUserId: string;
    pathname: string;
}) => {
    const { flagKey, flagsUserId, ruleKey, variationKey, pathname } = event;
    dispatchEvent(EventKey.FLAG_DECISION_FX, {
        'optimizely-flagKey': flagKey,
        'optimizely-ruleKey': ruleKey || FlagsConstants.UNRESOLVED,
        'optimizely-variationKey': variationKey || FlagsConstants.UNRESOLVED,
        'optimizely-userId': flagsUserId || FlagsConstants.UNRESOLVED,
        'optimizely-resolvedPath': pathname,
    });
};
