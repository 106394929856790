import { TggLogger } from '../logger';
import { OptimizelyFeatureFlags } from '@tgg/common-types';

export type FeatureFlagsRequestOptions = {
    logger: TggLogger;
};

export type TggFlag = { enabled: boolean; variationKey?: string | null };

export type BaseFlagVariations = 'off';
export type OptimizelyFlagVariations<T extends string> = T | BaseFlagVariations;

export type OptimizelyFlag<
    Variation extends string | null = BaseFlagVariations,
    Variables extends Record<string, unknown> | null = null,
> = TggFlag & {
    variationKey?: Variation;
    variables?: Variables;
    ruleKey?: string | null;
};

interface FlagTypeMap {
    [OptimizelyFeatureFlags.JJ_ADVISORY_MESSAGE_ENABLED]: JjAdvisoryMessageEnabledFlag;
    [OptimizelyFeatureFlags.MEMBERSHIP_TERMS_VISIBILITY]: MembershipTermsVisibilityFlag;
    [OptimizelyFeatureFlags.SAVINGS_VISIBILITY_VARIATIONS]: SavingsVisibilityVariationsFlag;
    [OptimizelyFeatureFlags.YOUR_PAYMENTS_BUTTON_VARIATIONS]: YourPaymentsButtonVariationsFlag;
    [OptimizelyFeatureFlags.AA_TEST]: AATestFlag;
    [OptimizelyFeatureFlags.API_VERSION_3_ENABLED]: ApiVersion3EnabledFlag;
}

export type FlagObject<T extends OptimizelyFeatureFlags> =
    T extends keyof FlagTypeMap ? FlagTypeMap[T] : OptimizelyFlag;

export type TggFlagsObject = {
    [key in OptimizelyFeatureFlags]: FlagObject<key>;
};

// Flags with A/B support
export type SavingsVisibilityVariations = OptimizelyFlagVariations<
    'v1' | 'v2' | 'v3'
>;
export type SavingsVisibilityVariationsFlag =
    OptimizelyFlag<SavingsVisibilityVariations>;

export type YourPaymentsButtonVariations = OptimizelyFlagVariations<
    | 'control_padlock_make_payment'
    | 'v1_padlock_pay_securely'
    | 'v2_badge_make_payment'
    | 'v3_badge_pay_securely'
>;
export type YourPaymentsButtonVariationsFlag =
    OptimizelyFlag<YourPaymentsButtonVariations>;

export type JjAdvisoryMessageEnabledVariations = OptimizelyFlagVariations<
    'control' | 'v1_advisory_message'
>;
export type JjAdvisoryMessageEnabledFlag =
    OptimizelyFlag<JjAdvisoryMessageEnabledVariations>;

export type MembershipTermsVisibilityVariations = OptimizelyFlagVariations<
    'Control' | 'V1'
>;
export type MembershipTermsVisibilityFlag =
    OptimizelyFlag<MembershipTermsVisibilityVariations>;

export type AATestVariations = OptimizelyFlagVariations<
    'FXAA_control' | 'FXAA_v1' | 'FXAA_v2'
>;
export type AATestFlag = OptimizelyFlag<
    AATestVariations,
    { enableDataLayer: boolean }
>;

export type ApiVersion3EnabledVariations = 'on' | 'off';
export type ApiVersion3EnabledFlag =
    OptimizelyFlag<ApiVersion3EnabledVariations>;

// Tracking

export enum OptimizelyVisitEvents {
    'VISIT_YOUR_MEMBERSHIP' = 'your_membership_visit',
    'VISIT_YOUR_PAYMENTS' = 'your_payments_visit',
    'VISIT_GYM_PAGE' = 'gym_page_visit',
    'VISIT_ABOUT_YOU' = 'about_you_visit',
    'VISIT_CHOOSE_MEMBERSHIP' = 'choose_membership_visit',
    'VISIT_CHOOSE_YOUR_ADDONS' = 'choose_your_addons_visit',
    'VISIT_PAYMENT_MONTHLY' = 'payment_monthly_visit',
    'VISIT_YOUR_EMAIL' = 'your_email_visit',
    'VISIT_FIND_YOUR_GYM' = 'find_your_gym_visit',
    'VISIT_FIND_A_GYM' = 'find_a_gym_visit',
}

export enum OptimizelyGlobalEvents {
    'START_JOIN_JOURNEY' = 'jj_start',
    'PURCHASE' = 'purchase',
}

export enum FlagsConstants {
    UNRESOLVED = 'unresolved',
    ROOT = 'root',
    DEBUG = 'debug',
}

export const OptimizelyEvents = {
    ...OptimizelyVisitEvents,
    ...OptimizelyGlobalEvents,
};

export type OptimizelyEventKey = keyof typeof OptimizelyEvents;
export type OptimizelyEvent = typeof OptimizelyEvents[OptimizelyEventKey];

export type OptimizelyDecisionEvent = {
    'optimizely-flagKey': OptimizelyFeatureFlags;
    'optimizely-ruleKey': string;
    'optimizely-variationKey': string;
    'optimizely-userId': string;
    'optimizely-resolvedPath': string;
};
