import { createTheme, alpha } from '@mui/material/styles';

import { ThemePalette } from './palette.type';

export const palette: ThemePalette = {
    primary: {
        main: '#2e94ff',
        dark: '#2a85f0',
        light: '#F1F9FD', // no specified lighter blue
    },
    secondary: {
        main: '#b2ff00',
        dark: '#7AA130',
        light: '#f7ffe5',
    },
    common: {
        white: '#FFFFFF',
        blue: '#0e0066',
        red: '#db202c',
        lightGrey: '#efefef',
    },
    grey: {
        // tints
        900: '#cce3fa',
        200: '#d7e8fa',
        100: '#e5effa',
        50: '#eef3f9',
    },
    success: {
        main: '#08be07',
        dark: '#587423', // not sure what goes here
        light: '#f2fcf2',
    },
    warning: {
        main: '#F58A1C',
        light: '#fefaf4',
    },
    error: {
        main: '#e22918',
        dark: '#fef5f5', // this is a lighter red
    },
    pino: {
        ultimate: '#f04f29',
        ultimateAlternate: '#fff6f4',
        standard: '#2e94ff',
        standardAlternate: '#eef3f9',
        offPeak: '#efefef',
        offPeakAlternate: '#fafafa',
    },
    other: {
        yellowPending: '#ea9628',
    },
};

export const boxShadow = `0 0 0.8rem 0 ${alpha(palette.primary.main, 0.4)}`;
export const lightGreyBoxShadow = `0 0 0 2px rgba(0, 0, 0, 0.1);`;

export const containerSmallWidth = '62.8rem';
export const containerLargeWidth = '84.5rem';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        largeDesktop: true; // adds the `largeDesktop` breakpoint
        extraLargeDesktop: true; // adds the `extraLargeDesktop` breakpoint
    }
}

export const customTheme = () => {
    const theme = createTheme({
        palette,
        breakpoints: {
            values: {
                mobile: 440,
                desktop: 768,
                largeDesktop: 1440,
                extraLargeDesktop: 1920,
            },
        },
        typography: {
            fontFamily: 'thegymgroupsans-Bold',
            htmlFontSize: 10,
            button: {
                textTransform: 'none',
            },
        },
        shape: {
            borderRadius: 3,
        },
        spacing: (px: number) => `${(px / 10).toFixed(1)}rem`,
    });

    const themeWithOverrides = createTheme({
        ...theme,
        components: {
            MuiTypography: {
                styleOverrides: {
                    root: {
                        '&.MuiTypography-h1&.MuiTypography-gutterBottom': {
                            marginBottom: '1rem',
                            [theme.breakpoints.up('desktop')]: {
                                marginBottom: '2rem',
                            },
                        },
                        '&.MuiTypography-h2&.MuiTypography-gutterBottom': {
                            marginBottom: '1rem',
                            [theme.breakpoints.up('desktop')]: {
                                marginBottom: '1.5rem',
                            },
                        },
                        '&.MuiTypography-h3&.MuiTypography-gutterBottom': {
                            marginBottom: '1rem',
                            [theme.breakpoints.up('desktop')]: {
                                marginBottom: '1.5rem',
                            },
                        },
                        '&.MuiTypography-h4&.MuiTypography-gutterBottom': {
                            marginBottom: '1rem',
                        },
                        '&.MuiTypography-caption&.MuiTypography-gutterBottom': {
                            marginBottom: '2rem',
                        },
                        h5: {
                            '&.MuiTypography-gutterBottom': {
                                marginBottom: '1rem',
                            },
                        },
                        h6: {
                            '&.MuiTypography-gutterBottom': {
                                marginBottom: '1rem',
                            },
                        },
                        '&.MuiTypography-subtitle1&.MuiTypography-gutterBottom':
                            { marginBottom: '1.5rem' },
                        '&.MuiTypography-body1&.MuiTypography-gutterBottom': {
                            marginBottom: '2rem',
                        },
                    },
                },
            },
        },
    });

    themeWithOverrides.typography = {
        ...theme.typography,
        h1: {
            fontFamily: 'thegymgroupsans-Headline',
            fontSize: '2.8rem',
            lineHeight: '3rem',
            letterSpacing: '-0.05rem',
            color: theme.palette.common.blue,
            [theme.breakpoints.up('desktop')]: {
                fontSize: '3.6rem',
                lineHeight: '3.6rem',
            },
        },
        h2: {
            fontFamily: 'thegymgroupsans-Headline',
            fontSize: '2.1rem',
            lineHeight: '2.7rem',
            letterSpacing: '-0.05rem',
            color: theme.palette.common.blue,
            [theme.breakpoints.up('desktop')]: {
                fontSize: '2.7rem',
                lineHeight: '3.2rem',
            },
        },
        h3: {
            fontFamily: 'thegymgroupsans-Headline',
            fontSize: '2.3rem',
            lineHeight: '2.6rem',
            letterSpacing: '-0.04rem',
            color: theme.palette.common.blue,
            [theme.breakpoints.up('desktop')]: {
                fontSize: '2.6rem',
                lineHeight: '3rem',
            },
        },
        h4: {
            fontFamily: 'thegymgroupsans-Headline',
            fontSize: '1.5rem',
            lineHeight: '2.1rem',
            letterSpacing: '-0.03rem',
            color: theme.palette.common.blue,
            [theme.breakpoints.up('desktop')]: {
                fontSize: '1.7rem',
                lineHeight: '2.1rem',
            },
        },
        h5: {
            fontFamily: 'thegymgroupsans-Headline',
            fontSize: '1.5rem',
            lineHeight: '2.1rem',
            letterSpacing: '-0.03rem',
            color: theme.palette.common.blue,
        },
        h6: {
            fontFamily: 'thegymgroupsans-Headline',
            fontSize: '1.4rem',
            lineHeight: '1.8rem',
            letterSpacing: '-0.03rem',
            color: theme.palette.common.blue,
        },
        caption: {
            fontFamily: 'thegymgroupsans-Bold',
            fontSize: '1.6rem',
            lineHeight: '2.4rem',
            letterSpacing: 0,
            color: theme.palette.common.blue,
            fontWeight: 'normal',
            [theme.breakpoints.up('desktop')]: {
                fontSize: '1.8rem',
                lineHeight: '2.6rem',
            },
        },
        body1: {
            fontFamily: 'thegymgroupsans-Bold',
            fontSize: '1.4rem',
            lineHeight: '2rem',
            letterSpacing: 0,
            color: theme.palette.common.blue,
            fontWeight: 'normal',
            [theme.breakpoints.up('desktop')]: {
                fontSize: '1.6rem',
                lineHeight: '2.2rem',
            },
        },
        body2: {
            fontFamily: 'thegymgroupsans-Bold',
            fontSize: '1.4rem',
            lineHeight: '2rem',
            color: theme.palette.common.blue,
            fontWeight: 'normal',
            letterSpacing: 0,
            [theme.breakpoints.up('desktop')]: {
                fontSize: '1.6rem',
                lineHeight: '2.2rem',
            },
        },
        subtitle1: {
            fontFamily: 'thegymgroupsans-Bold',
            fontSize: '1rem',
            lineHeight: '1.4rem',
            color: theme.palette.common.blue,
            letterSpacing: 0,
            fontWeight: 'normal',
            [theme.breakpoints.up('desktop')]: {
                fontSize: '1.2rem',
                lineHeight: '1.8rem',
            },
        },
        subtitle2: {
            fontFamily: 'thegymgroupsans-Headline',
            fontSize: '1rem',
            lineHeight: '1.4rem',
            color: theme.palette.common.blue,
            fontWeight: 'bold',
            letterSpacing: 0,
            [theme.breakpoints.up('desktop')]: {
                fontSize: '1.2rem',
                lineHeight: '1.8rem',
            },
        },
    };

    return themeWithOverrides;
};

export const theme = customTheme();
