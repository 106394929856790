import { OptimizelyDecision } from '@optimizely/optimizely-sdk';
import { ListenerPayload, ReactSDKClient, enums } from '@optimizely/react-sdk';
import { useCallback, useEffect, useState } from 'react';

import { dispatchOptimizelyGaEvent } from '../helpers/dispatchOptimizelyGaEvent';
import { trackOptimizelyEventClient } from '../helpers/optimizelyTrackEvent';
import { ApplicationIdentifier, Nullable } from '@tgg/common-types';
import {
    CustomOptimizelyEventAttributes,
    FlagsConstants,
    OptimizelyEvent,
    TggFlagsObject,
    getAppLogger,
} from '@tgg/util';

export const createDecisionEventListener =
    (config: { resolvedUserId: string; pathname: string }) =>
    async (
        event: ListenerPayload & {
            decisionInfo: OptimizelyDecision;
        },
        // eslint-disable-next-line unicorn/consistent-function-scoping
    ) => {
        const { enabled, ruleKey, variationKey, variables, flagKey } =
            event.decisionInfo;
        const { resolvedUserId, pathname } = config;
        /* istanbul ignore else */
        if (enabled && variables?.enableDataLayer === true) {
            dispatchOptimizelyGaEvent({
                flagKey,
                flagsUserId: resolvedUserId,
                pathname,
                ruleKey,
                variationKey,
            });
        }
    };

export const useOptimizelyTracking = (config: {
    appIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED;
    useOptimizelyReactSdk: boolean;
    client: Nullable<ReactSDKClient>;
    resolvedUserId: string;
    pathname: string;
    resolvedFlags: TggFlagsObject;
}) => {
    const [isClientInitialized, setIsClientInitialized] = useState(false);
    const {
        appIdentifier,
        client,
        useOptimizelyReactSdk,
        resolvedUserId,
        pathname,
        resolvedFlags,
    } = config;

    // decision GA event when not using Fe client
    useEffect(() => {
        if (resolvedFlags && !useOptimizelyReactSdk) {
            Object.keys(resolvedFlags).forEach(flagKey => {
                const flag = resolvedFlags[flagKey as keyof TggFlagsObject];
                // @ts-ignore
                if (flag.enabled && flag.variables?.enableDataLayer === true) {
                    dispatchOptimizelyGaEvent({
                        flagKey,
                        flagsUserId: resolvedUserId,
                        pathname,
                        ruleKey: flag.ruleKey,
                        variationKey: flag.variationKey,
                    });
                }
            });
        }
    }, [resolvedFlags, resolvedUserId, pathname, useOptimizelyReactSdk]);

    // decision GA event when using Fe client
    useEffect(() => {
        if (useOptimizelyReactSdk && client && !isClientInitialized) {
            const decisionEventListener = createDecisionEventListener({
                resolvedUserId,
                pathname,
            });
            client.notificationCenter.addNotificationListener<
                ListenerPayload & {
                    decisionInfo: OptimizelyDecision;
                }
            >(enums.NOTIFICATION_TYPES.DECISION, decisionEventListener);

            // TODO: add GA flag track event
            // client.notificationCenter.addNotificationListener(
            //     enums.NOTIFICATION_TYPES.TRACK,
            //     event =>
            //         console.log(
            //             `optimizelyFeClient - ${enums.NOTIFICATION_TYPES.TRACK}`,
            //             event,
            //         ),
            // );

            setIsClientInitialized(true);
        }
    }, [
        resolvedUserId,
        pathname,
        useOptimizelyReactSdk,
        client,
        isClientInitialized,
    ]);

    const trackFlagsEvent = useCallback(
        (
            optimizelyEvent: OptimizelyEvent,
            attributes?: CustomOptimizelyEventAttributes,
        ) => {
            /* istanbul ignore else */
            if (appIdentifier !== FlagsConstants.UNRESOLVED) {
                const logger = getAppLogger({
                    applicationIdentifier: appIdentifier,
                });
                if (useOptimizelyReactSdk && client) {
                    client.track(
                        optimizelyEvent,
                        undefined,
                        { pathname },
                        attributes ?? {},
                    );
                } else {
                    trackOptimizelyEventClient(
                        optimizelyEvent,
                        {
                            customAttributes:
                                attributes ?? /* istanbul ignore next */ {},
                            userId: resolvedUserId,
                            pathname,
                        },
                        { logger },
                    );
                }
            }
        },
        [
            appIdentifier,
            client,
            useOptimizelyReactSdk,
            resolvedUserId,
            pathname,
        ],
    );

    return { trackFlagsEvent, isClientInitialized };
};
