/* eslint-disable @typescript-eslint/no-floating-promises */
import { OptimizelyDecision } from '@optimizely/optimizely-sdk';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { useAppConfigContext } from '../appConfigContext';
import { FlagsContext, FlagsContextProperties } from './flagsContext';
import { useOptimizelyFeClient } from './hooks/useOptimizelyFeClient';
import { useOptimizelyTracking } from './hooks/useOptimizelyTracking';
import { useUserIdentification } from './hooks/useUserIdentification';
import { Nullable, OptimizelyFeatureFlags } from '@tgg/common-types';
import { TggFlagsObject, defaultFlags, mapOptimizelyFlags } from '@tgg/util';

export type FlagsProviderProperties = {
    children: ReactNode;
    flagsContextData: {
        flags: Nullable<TggFlagsObject>;
        flagsUserId: string;
        pathname: string;
        sdkKey: string;
        useOptimizelyReactSdk: boolean;
    };
};

export function FlagsProvider({
    children,
    flagsContextData,
}: FlagsProviderProperties) {
    const [flagsLoaded, setFlagsLoaded] = useState(false);

    const { flags, flagsUserId, pathname, sdkKey, useOptimizelyReactSdk } =
        flagsContextData ?? /* istanbul ignore next */ {};

    const [resolvedFlags, setResolvedFlags] = useState({
        ...defaultFlags,
        ...flags,
    });

    const {
        appConfig: { appIdentifier },
    } = useAppConfigContext();

    const userConfig = useMemo(
        () => ({
            flagsUserId,
            pathname,
            useOptimizelyReactSdk,
        }),
        [flagsUserId, pathname, useOptimizelyReactSdk],
    );

    const { isUserInitialized, resolvedUserId } =
        useUserIdentification(userConfig);

    const clientConfig = useMemo(
        () => ({
            sdkKey,
            resolvedUserId,
            pathname,
            useOptimizelyReactSdk,
            isUserInitialized,
        }),
        [
            sdkKey,
            isUserInitialized,
            resolvedUserId,
            pathname,
            useOptimizelyReactSdk,
        ],
    );

    const { client, isClientError, isClientLoaded } =
        useOptimizelyFeClient(clientConfig);

    const trackingConfig = useMemo(
        () => ({
            appIdentifier,
            client,
            useOptimizelyReactSdk,
            resolvedUserId,
            pathname,
            resolvedFlags,
        }),

        [
            appIdentifier,
            client,
            useOptimizelyReactSdk,
            resolvedUserId,
            pathname,
            resolvedFlags,
            isClientLoaded,
        ],
    );

    const { trackFlagsEvent, isClientInitialized } =
        useOptimizelyTracking(trackingConfig);

    // get feature flags client side when using fe client
    useEffect(() => {
        if (isClientInitialized && client && useOptimizelyReactSdk) {
            const flagsResult = client.decideAll(undefined, undefined, {
                pathname,
            });
            /* istanbul ignore else */
            if (flagsResult) {
                setResolvedFlags({
                    ...defaultFlags,
                    ...mapOptimizelyFlags(
                        flagsResult as unknown as Record<
                            OptimizelyFeatureFlags,
                            OptimizelyDecision
                        >,
                    ),
                });
            }
            setFlagsLoaded(true);
        }
    }, [isClientInitialized, client, pathname, useOptimizelyReactSdk]);

    const contextValue: FlagsContextProperties = useMemo(() => {
        return {
            flags: resolvedFlags,
            flagsUserId: resolvedUserId,
            pathname,
            trackFlagsEvent,
        };
    }, [resolvedFlags, resolvedUserId, pathname, trackFlagsEvent]);

    const render =
        !useOptimizelyReactSdk ||
        (useOptimizelyReactSdk && flagsLoaded) ||
        (useOptimizelyReactSdk && isClientError);

    return (
        <FlagsContext.Provider value={contextValue}>
            {render && children}
        </FlagsContext.Provider>
    );
}
