import { TggFlagsObject } from './featureFlags.types';
import { OptimizelyFeatureFlags } from '@tgg/common-types';

export const defaultFlags: TggFlagsObject = {
    [OptimizelyFeatureFlags.CLEAR_CACHE_ON_FLAGS_CHANGE]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.HEADLINE_RIBBON_ENABLED]: {
        enabled: true,
    },
    [OptimizelyFeatureFlags.JJ_ADVISORY_MESSAGE_ENABLED]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.MEMBERSHIP_TERMS_VISIBILITY]: {
        enabled: false,
        variationKey: 'Control',
    },
    [OptimizelyFeatureFlags.PAYMENT_IN_ARREARS]: { enabled: false },
    [OptimizelyFeatureFlags.PURGE_ALL_CACHE_TRIGGER]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.PURGE_CACHE_BY_AMPLIENCE_TEST_FLAG]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.PURGE_CACHE_BY_URL_TEST_FLAG]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.RENEWALS_ENABLED]: { enabled: false },
    [OptimizelyFeatureFlags.SUPPORT_CENTRE_ENABLED]: {
        enabled: false,
    },
    [OptimizelyFeatureFlags.AA_TEST]: {
        enabled: false,
        variationKey: 'FXAA_control',
        variables: {
            enableDataLayer: true,
        },
    },
    [OptimizelyFeatureFlags.GUEST_PASS_ENABLED]: { enabled: false },
    [OptimizelyFeatureFlags.RENEWAL_UPSELL_ENABLED]: { enabled: false },
    [OptimizelyFeatureFlags.SAVINGS_VISIBILITY_VARIATIONS]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.YOUR_PAYMENTS_BUTTON_VARIATIONS]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.API_VERSION_3_ENABLED]: {
        enabled: false,
        variationKey: 'off',
    },
    [OptimizelyFeatureFlags.USE_OPTIMIZELY_REACT_SDK]: {
        enabled: true,
    },
};
