import { useEffect, useState } from 'react';

import { Nullable } from '@tgg/common-types';
import {
    FlagsConstants,
    getOptimizelyUserIdClientSide,
    userIdentifierCookieApi,
} from '@tgg/util';

export const useUserIdentification = (config: {
    flagsUserId: Nullable<string>;
    pathname: string;
    useOptimizelyReactSdk: boolean;
}) => {
    const { flagsUserId, pathname, useOptimizelyReactSdk } = config;
    const [resolvedUserId, setResolvedUserId] = useState(flagsUserId);
    const [isUserInitialized, setIsUserInitialized] = useState(false);

    useEffect(() => {
        if (!isUserInitialized) {
            const { resolvedId: userIdFromCookie } =
                getOptimizelyUserIdClientSide({
                    shouldReset: useOptimizelyReactSdk,
                });

            // resolve cookie id when be id not present
            if (resolvedUserId === FlagsConstants.UNRESOLVED || !resolvedUserId)
                setResolvedUserId(userIdFromCookie);

            // prioritize cookie id when using OptimizelyReactSdk
            if (
                useOptimizelyReactSdk &&
                resolvedUserId !== userIdFromCookie &&
                userIdFromCookie !== FlagsConstants.UNRESOLVED
            )
                setResolvedUserId(userIdFromCookie);

            // prioritize be id when not using OptimizelyReactSdk
            if (
                !useOptimizelyReactSdk &&
                resolvedUserId &&
                resolvedUserId !== userIdFromCookie &&
                resolvedUserId !== FlagsConstants.UNRESOLVED
            )
                userIdentifierCookieApi.browser.setCookie(resolvedUserId);

            setIsUserInitialized(true);
        }
    }, [resolvedUserId, isUserInitialized, pathname, useOptimizelyReactSdk]);

    return {
        isUserInitialized,
        resolvedUserId: resolvedUserId as string,
    };
};
