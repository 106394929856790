import { ReactSDKClient } from '@optimizely/react-sdk';
import { useEffect, useState } from 'react';

import { initOptimizelyFeClient } from '../optimizelyFeClient/initOptimizelyFeClient';
import { Nullable } from '@tgg/common-types';

let optimizelyFeClient: Nullable<ReactSDKClient> = null;

export const useOptimizelyFeClient = (config: {
    sdkKey: string;
    resolvedUserId: string;
    useOptimizelyReactSdk: boolean;
    isUserInitialized: boolean;
}) => {
    const [isClientError, setIsClientError] = useState(false);
    const [isClientLoaded, setIsClientLoaded] = useState(false);

    const { resolvedUserId, sdkKey, useOptimizelyReactSdk, isUserInitialized } =
        config;

    useEffect(() => {
        if (!isClientLoaded && isUserInitialized && useOptimizelyReactSdk) {
            /* istanbul ignore next */
            if (
                optimizelyFeClient &&
                optimizelyFeClient.user.id === resolvedUserId
            ) {
                setIsClientLoaded(true);
            } else {
                initOptimizelyFeClient({
                    sdkKey,
                    flagsUserId: resolvedUserId,
                    successCallback: client => {
                        optimizelyFeClient = client;
                        setIsClientLoaded(true);
                    },
                    errorCallback: () => {
                        setIsClientError(true);
                    },
                });
            }
        }
    }, [
        isClientLoaded,
        sdkKey,
        resolvedUserId,
        useOptimizelyReactSdk,
        isUserInitialized,
    ]);

    return {
        isClientLoaded,
        isClientError,
        client: isClientLoaded && !isClientError ? optimizelyFeClient : null,
    };
};
