/* istanbul ignore file */
import { createContext } from 'react';

import { appConfig } from './appConfigDefaults';
import { ApplicationIdentifier } from '@tgg/common-types';
import { FlagsConstants } from '@tgg/util';

export type AppConfigProperties = {
    appConfig: {
        appIdentifier: ApplicationIdentifier | FlagsConstants.UNRESOLVED;
    };
};

export const AppConfigContext = createContext<AppConfigProperties>({
    appConfig,
});
