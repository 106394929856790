import { type ReactSDKClient, createInstance } from '@optimizely/react-sdk';

export const initOptimizelyFeClient = async (config: {
    sdkKey: string;
    flagsUserId: string;
    successCallback: (client: ReactSDKClient) => void;
    errorCallback: (error: unknown) => void;
}) => {
    const { sdkKey, errorCallback, successCallback, flagsUserId } = config;
    try {
        const optimizelyFeClient = createInstance({ sdkKey });

        await optimizelyFeClient.onReady();
        if (optimizelyFeClient?.getOptimizelyConfig() !== null) {
            await optimizelyFeClient?.setUser({
                id: flagsUserId,
            });

            successCallback(optimizelyFeClient);
        } else {
            throw new Error('Optimizely client returned empty config');
        }
    } catch (error) {
        errorCallback(error);
    }
};
