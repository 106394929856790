export enum OptimizelyFeatureFlags {
    CLEAR_CACHE_ON_FLAGS_CHANGE = 'clear_cache_on_flags_change',
    HEADLINE_RIBBON_ENABLED = 'headline_ribbon_enabled',
    JJ_ADVISORY_MESSAGE_ENABLED = 'ace-83',
    MEMBERSHIP_TERMS_VISIBILITY = 'tcb-52',
    PAYMENT_IN_ARREARS = 'payment_in_arrears',
    PURGE_ALL_CACHE_TRIGGER = 'purge_all_cache_trigger',
    PURGE_CACHE_BY_AMPLIENCE_TEST_FLAG = 'purge_cache_by_amplience_test_flag',
    PURGE_CACHE_BY_URL_TEST_FLAG = 'purge_cache_by_url_test_flag',
    RENEWALS_ENABLED = 'renewals_enabled',
    SUPPORT_CENTRE_ENABLED = 'support_centre_enabled',
    AA_TEST = 'tcb-51',
    GUEST_PASS_ENABLED = 'guest_pass_enabled',
    RENEWAL_UPSELL_ENABLED = 'renewal_upsell_enabled',
    SAVINGS_VISIBILITY_VARIATIONS = 'tcb-48',
    API_VERSION_3_ENABLED = 'api_version_3_enabled',
    YOUR_PAYMENTS_BUTTON_VARIATIONS = 'tcb-45',
    USE_OPTIMIZELY_REACT_SDK = 'use_optimizely_react_sdk',
}
